export const isValidJSONString = (value: unknown): boolean => {
  if (!value) return false;

  try {
    JSON.parse(value as string);
  } catch (e) {
    return false;
  }

  return true;
};

export const JSONSafeParse = <T = any>(value: string): T => {
  return isValidJSONString(value) ? (JSON.parse(value) as T) : ({} as T);
};
