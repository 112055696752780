import { ModalDialog } from '@/components/shared';
import { type ModalDialogProps } from '@/components/shared/ModalDialog';
import { type ReactNode, createContext, useCallback, useState } from 'react';
export const ModalContext = createContext<ModalContextProps>(null as any);
export interface ModalContextProps {
  showModal: (action: ModalConfig) => void;
  hideModal: () => void;
}
interface ModalConfig {
  id?: string;
  content: JSX.Element;
  modalDialogProps?: Omit<ModalDialogProps, 'id' | 'isOpen' | 'setIsOpen'>;
}
export const ModalProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [modals, setModals] = useState<ModalConfig[]>([]);
  const showModal = useCallback((action: ModalConfig) => setModals(prev => [...prev, action]), []);
  const hideModal = useCallback(() => setModals(prev => prev.slice(0, prev.length - 1)), []);
  const value: ModalContextProps = {
    showModal,
    hideModal
  };
  return <ModalContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="ModalProvider" data-sentry-source-file="ModalProvider.tsx">
      {children}
      {/* 
        This is v ugly, but after much research/testing, have found this to be the only to support nested
        @headlessui dialogs properly (ie avoiding odd focus bevavior, and maintaining components' state properly when 
        communicating between dialogs). Support for multiple @headlessui/react dialogs requires a nested stack,
        and a recursive "stack" component does not appear to work. If we ever need more than 3 dialogs at a time 🤢,
        add an additional modals[3] child below with same pattern.
        docs: https://headlessui.com/react/dialog
        issue: https://github.com/tailwindlabs/headlessui/issues/493
       */}
      {modals?.[0] && <ModalDialog isOpen={true} setIsOpen={hideModal} id={modals[0]?.id || ''} {...modals[0]?.modalDialogProps || {}}>
          {modals[0]?.content}
          {modals?.[1] && <ModalDialog isOpen={true} setIsOpen={hideModal} id={modals[1]?.id || ''} {...modals[1]?.modalDialogProps || {}}>
              {modals[1]?.content}
              {modals?.[2] && <ModalDialog isOpen={true} setIsOpen={hideModal} id={modals[2]?.id || ''} {...modals[2]?.modalDialogProps || {}}>
                  {modals[2]?.content}
                </ModalDialog>}
            </ModalDialog>}
        </ModalDialog>}
    </ModalContext.Provider>;
};