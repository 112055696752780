import type { FC } from 'react';
import toast, { Toaster, type ToastPosition } from 'react-hot-toast';
interface NotificationContainerProps extends React.ComponentPropsWithoutRef<'div'> {
  children?: React.ReactNode;
}
export const NotificationContainer: FC<NotificationContainerProps> = ({
  className,
  children,
  ...props
}) => {
  return <Toaster {...props} data-sentry-element="Toaster" data-sentry-component="NotificationContainer" data-sentry-source-file="Notification.tsx"></Toaster>;
};
export enum NotificationType {
  Info = 'info',
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
}
const showNotification = (text: string, type: NotificationType, duration = 4000, position: ToastPosition = 'bottom-center', onClose?: () => void) => {
  toast.dismiss();
  switch (type) {
    case NotificationType.Error:
      toast.error(text, {
        duration,
        position
      });
      break;
    case NotificationType.Success:
      toast.success(text, {
        duration,
        position
      });
      break;
    case NotificationType.Warning:
      toast(t => <span>
            {text}
            <div style={{
          marginTop: '15px',
          textAlign: 'right'
        }}>
              <button onClick={() => {
            toast.dismiss(t.id);
            if (onClose) onClose();
          }} style={{
            backgroundColor: 'white',
            border: '1px solid #ccc',
            padding: '5px 10px',
            color: 'black',
            cursor: 'pointer',
            fontWeight: 'bold',
            borderRadius: '4px'
          }}>
                OKAY
              </button>
            </div>
          </span>, {
        duration: Infinity,
        position,
        style: {
          backgroundColor: 'orange',
          color: 'black',
          padding: '15px'
        }
      });
      break;
    default:
      toast(text, {
        duration,
        position
      });
  }
};
export default showNotification;