import { conditionalClasses } from '@/utils/tailwind';
import { useRouter } from 'next/navigation';
import { Fragment, useState, type FC } from 'react';
import { ActionButton } from './ActionButton';
import { CustomLink } from './CustomLink';
type PageItem = {
  title: string;
  component: React.ReactNode;
  validator?: boolean;
  errorHandler: () => boolean;
  disableNext?: boolean;
};
interface ContainerProps extends React.ComponentPropsWithoutRef<'div'> {
  pages: PageItem[];
  pageTitle: string;
  pageComponent: React.ReactNode;
  errorHandler: () => boolean;
  disableNext?: boolean;
  displayPager: boolean;
  currentPageNumber: number;
  maxPage: number;
  setCurrentPageNumber: React.Dispatch<React.SetStateAction<number>>;
  onCancel?: () => void;
  onFinish: () => void;
  isLoading?: boolean;
  save: () => Promise<void>;
}
export const PageWizard: FC<ContainerProps> = ({
  className,
  pages,
  pageComponent,
  pageTitle,
  errorHandler,
  disableNext,
  displayPager,
  currentPageNumber,
  maxPage,
  setCurrentPageNumber,
  onCancel,
  onFinish,
  isLoading,
  save,
  ...props
}) => {
  const router = useRouter();
  const [isSaving, setIsSaving] = useState(false);
  const hasErrors = () => {
    return errorHandler();
  };
  const showPager = () => {
    if (!displayPager) return null;
    return <div className="mt-4 flex flex-row items-center justify-center pl-10" data-sentry-component="showPager" data-sentry-source-file="PageWizard.tsx">
        {pages.map((item, index) => {
        return <Fragment key={index}>
              <div className="z-10">
                <div className="mt-4 w-8">
                  <div key={index} className={'rounded-full px-3 py-1 text-white ' + (currentPageNumber - 1 <= index - 1 ? '  bg-podi-primary-disabled' : ' bg-podi-primary font-bold')}>
                    {index + 1}
                  </div>
                </div>
                <div className={'-ml-4 mt-2 h-[32px] w-[100px]'}></div>
                <div className="relative">
                  <div className={'absolute -ml-[34px] -mt-7 w-[100px] items-center justify-center overflow-x-clip text-center text-xs/[1rem]' + (currentPageNumber - 1 <= index ? '' : ' font-bold')}>
                    {item.title}
                  </div>
                </div>
              </div>
              {index < pages.length - 1 ? <div className={'-ml-[55px] -mt-6 h-2 w-14 bg-podi-primary ' + (currentPageNumber - 1 <= index ? 'bg-podi-primary-disabled' : 'bg-podi-primary')}></div> : null}
              {index < pages.length - 1 ? <div className={'-ml-12 -mt-6 h-2 w-14 bg-podi-primary ' + (currentPageNumber - 1 <= index ? 'bg-podi-primary-disabled' : 'bg-podi-primary')}></div> : null}
              {index < pages.length - 1 ? <div className={'-mr-0.5 -mt-6 h-2 w-14 bg-podi-primary ' + (currentPageNumber - 1 <= index ? 'bg-podi-primary-disabled' : 'bg-podi-primary')}></div> : null}
            </Fragment>;
      })}
      </div>;
  };
  const showButtons = () => {
    return <div className="flex flex-col items-end justify-end gap-5 pb-2 pt-4 sm:flex-row sm:items-center " data-sentry-component="showButtons" data-sentry-source-file="PageWizard.tsx">
        <CustomLink href="#" id="close-modal" text="Cancel" onClick={onCancel ? onCancel : () => router.back()} data-sentry-element="CustomLink" data-sentry-source-file="PageWizard.tsx" />
        <div className="flex items-center justify-end gap-5">
          <ActionButton id="show-popup" text="  Previous" className="mx-0 w-auto px-3 py-3" type="submit" onClick={() => {
          if (currentPageNumber > 1) {
            setCurrentPageNumber(prevPage => prevPage - 1);
            return;
          }
        }} isDisabled={currentPageNumber == 1} data-sentry-element="ActionButton" data-sentry-source-file="PageWizard.tsx" />
          <ActionButton isLoading={isLoading} id="show-popup" text={currentPageNumber == maxPage ? 'Save' : 'Next'} onMouseEnter={() => {
          if (isLoading || isSaving) return;
          hasErrors();
        }} className="mx-0 w-auto px-3 py-3" type="submit" onClick={async () => {
          if (errorHandler()) {
            return;
          }
          if (currentPageNumber < maxPage) {
            setCurrentPageNumber(prevPage => prevPage + 1);
            return;
          }
          setIsSaving(true);
          await save();
          setIsSaving(false);
        }} isDisabled={disableNext || isSaving || false} data-sentry-element="ActionButton" data-sentry-source-file="PageWizard.tsx" />
        </div>
      </div>;
  };
  return <div className={conditionalClasses('mx-auto max-w-7xl px-4 sm:px-6 lg:px-8', className)} {...props} data-sentry-component="PageWizard" data-sentry-source-file="PageWizard.tsx">
      {showPager()}
      <div className="divide-y pb-4 pt-10">
        <span className="border-separate text-lg font-bold">{pageTitle}</span>
      </div>
      {pageComponent}
      {showButtons()}
    </div>;
};