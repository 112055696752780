import { conditionalClasses } from '@/utils/tailwind';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
export interface Option {
  name?: string;
  value?: any;
  action?: () => void;
  email?: string;
  avatarUrl?: string;
}
interface SelectInputProps extends React.ComponentPropsWithoutRef<'select'> {
  selected: any;
  label?: string;
  options: Option[];
  setSelected: React.Dispatch<React.SetStateAction<any>>;
  selectForObject?: boolean;
  disabled?: boolean;
  modifyItemData?: any;
  fieldName?: string;
  error?: string;
  className?: string;
  isLoading?: boolean;
  required?: boolean;
  listUp?: boolean;
  containerClassName?: string;
  icon?: React.ReactNode;
}
export const SelectInput = ({
  selected,
  setSelected,
  label,
  options,
  selectForObject,
  disabled,
  modifyItemData,
  fieldName,
  error,
  isLoading,
  required,
  className,
  listUp,
  containerClassName,
  icon
}: SelectInputProps) => {
  return <>
      <Listbox as="div" value={selected} onChange={value => {
      fieldName && modifyItemData(fieldName, value[fieldName]);
      setSelected(value);
    }} data-sentry-element="Listbox" data-sentry-source-file="SelectInput.tsx">
        {({
        open
      }) => <div className={containerClassName}>
            {label && <Listbox.Label as="label" className="mb-2 block text-sm font-medium leading-3 text-gray-900">
                {label}
                {required && ' *'}
              </Listbox.Label>}
            <div className="relative">
              <Listbox.Button as="button" className={conditionalClasses('relative w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-gray-900 focus:outline-none focus:ring-2 focus:ring-podi-primary sm:text-sm sm:leading-6', {
            'focus-ring-0 pointer-events-none bg-gray-50 shadow-none ring-0': disabled || isLoading,
            'pl-10': icon // Add padding to prevent overlap when icon is used
          }, className)}>
                <span className="block min-h-6">{selectForObject ? selected?.name || selected?.description : selected}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className={conditionalClasses('h-5 w-5 text-base', {
                'text-gray-400': disabled || isLoading
              })} aria-hidden="true" />
                </span>
                {icon && <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">{icon}</div>}
              </Listbox.Button>

              <Transition show={!disabled && !isLoading && open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Listbox.Options as="div" className={`absolute ${listUp && 'bottom-11'} z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}>
                  {options?.map((option: any, index: number) => <Listbox.Option as="div" key={index} className={({
                active
              }) => conditionalClasses(active ? 'bg-podi-primary text-white' : 'text-gray-900', 'relative cursor-pointer select-none py-2 pl-3 pr-9')} value={option?.value ? option?.value : option}>
                      {({
                  selected,
                  active
                }) => <>
                          <span className={conditionalClasses(selected ? 'font-semibold' : 'font-normal', 'block')}>{option?.name || option?.description}</span>

                          {selected ? <span className={conditionalClasses(active ? 'text-white' : 'text-podi-primary', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span> : null}
                        </>}
                    </Listbox.Option>)}
                </Listbox.Options>
              </Transition>
            </div>
          </div>}
      </Listbox>
      {error && <p className="text-sm text-red-500">{error}</p>}
    </>;
};