export const SimpleProgressBar = ({
  progress = 0
}: {
  progress?: number;
}) => {
  return <div className="relative h-6" data-sentry-component="SimpleProgressBar" data-sentry-source-file="SimpleProgressBar.tsx">
      <div className="absolute bottom-0 left-0 top-0 h-full w-full bg-podi-primary-disabled"></div>
      <div style={{
      width: `${progress}%`
    }} className="absolute bottom-0 left-0 top-0 h-full bg-podi-primary transition-all duration-150"></div>
      <div className="absolute bottom-0 left-0 top-0 flex h-full w-full items-center justify-center">
        <span className="text-xs font-bold text-white">{progress}%</span>
      </div>
    </div>;
};