import { conditionalClasses } from '@/utils/tailwind';
import React, { type ChangeEvent } from 'react';
interface CheckboxProps {
  label?: string;
  checked: boolean | null;
  name?: string;
  onChange: (isChecked: boolean) => void;
  inputClasses?: string;
  labelClasses?: string;
  disabled?: boolean;
  error?: string;
}
export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  name,
  inputClasses,
  labelClasses,
  disabled,
  error
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    onChange(isChecked);
  };
  return <>
      <label className={conditionalClasses('flex items-center text-sm', disabled ? 'cursor-not-allowed' : 'cursor-pointer', labelClasses)} htmlFor={name}>
        <input id={name} type="checkbox" checked={checked ? checked : false} onChange={handleChange} className={conditionalClasses('mb-2 mr-2 mt-2 h-4 w-4 cursor-pointer rounded border-gray-400 text-podi-primary-hover focus:ring-podi-primary-hover', {
        'cursor-not-allowed text-gray-500 opacity-50': disabled
      }, inputClasses)} disabled={disabled} />
        {error && <p className="text-sm text-red-500">{error}</p>}
        {label}
      </label>
    </>;
};