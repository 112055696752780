import { conditionalClasses } from '@/utils/tailwind';
import Link from 'next/link';
import type { FC } from 'react';
import Loading from '../../(dashboard)/Loading';
type Size = 'sm' | 'md' | 'lg';
type Variant = 'primary' | 'secondary' | 'ghost' | 'outline';
interface ActionButtonProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  size?: Size;
  variant?: Variant;
  isDisabled?: boolean;
  isLoading?: boolean;
  text: React.ReactNode;
  type?: 'submit';
  id?: string;
  href?: string;
}
const defaultClasses: string = `button shadow-button mx-auto w-64 rounded-md bg-podi-primary px-8 py-4 font-sans font-medium uppercase tracking-wide text-md text-white duration-300 hover:bg-podi-primary-hover focus:outline-podi-primary focus:ring-2 focus:ring-podi-primary focus:ring-offset-1 focus:ring-offset-podi-primary disabled:bg-podi-primary-disabled disabled:shadow-none disabled:drop-shadow-none drop-shadow-md hover:drop-shadow-none hover:focus:ring-offset-0 hover:focus:ring-podi-primary-hover disabled:bg-podi-primary-disabled`;
const SizeClassesMap: Record<Size, string> = {
  sm: 'w-auto mx-0 py-1 px-2',
  md: 'w-auto mx-0 py-2 px-4',
  lg: 'w-auto mx-0 py-3 px-6'
};
const VariantClassesMap: Record<Variant, string> = {
  primary: 'bg-podi-primary hover:bg-podi-primary-hover focus:ring-podi-primary focus:ring-offset-0',
  secondary: 'bg-podi-secondary hover:bg-podi-secondary-hover focus:ring-podi-secondary focus:ring-offset-0',
  ghost: 'bg-transparent hover:bg-gray-100 text-primary focus:ring-podi-primary focus:ring-offset-0',
  outline: 'bg-transparent border border-podi-primary text-primary hover:bg-gray-100 focus:ring-podi-primary focus:ring-offset-0'
};
export const ActionButton: FC<ActionButtonProps> = ({
  size,
  variant,
  className,
  isDisabled,
  isLoading,
  children,
  type,
  href,
  text,
  id,
  ...props
}) => {
  const sizeClasses = size ? SizeClassesMap[size] : '';
  const variantClasses = variant ? VariantClassesMap[variant] : '';
  return href ? <Link id={id} className={conditionalClasses(defaultClasses, sizeClasses, variantClasses, className)} href={href}>
      {text} {children} {isLoading && <Loading />}
    </Link> : <button id={id} disabled={isLoading || isDisabled} type={type} className={conditionalClasses(defaultClasses, sizeClasses, variantClasses, {
    'cursor-not-allowed': isLoading || isDisabled
  }, className)} {...props}>
      {text} {children} {isLoading && <Loading />}
    </button>;
};