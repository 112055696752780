import { conditionalClasses } from '@/utils/tailwind';
import { Combobox as HUICombobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Fragment, useState } from 'react';
import { type FieldError } from 'react-hook-form';
import { type SelectOption, type SelectValue } from './Select';
interface Props<T = SelectValue> {
  clearable?: boolean;
  disabled?: boolean;
  fieldError?: FieldError;
  icon?: JSX.Element;
  id?: string;
  label?: string;
  onChange: (value: T) => void;
  options: SelectOption<T>[];
  placeholder?: string;
  required?: boolean;
  value: T;
  wrapperClassName?: string;
  containerClassName?: string;
  buttonClassName?: string;
  optionsClassName?: string;
}
export function Combobox({
  clearable = true,
  disabled = false,
  fieldError,
  icon,
  id,
  label,
  onChange,
  options,
  placeholder,
  required = false,
  value = '',
  wrapperClassName,
  containerClassName,
  buttonClassName,
  optionsClassName,
  ...comboboxProps
}: Props) {
  if (!placeholder) placeholder = `Search ${label || 'Option'}`;
  const [query, setQuery] = useState('');
  const filteredOptions = query === '' ? options : options.filter(option => {
    return option.label.toLowerCase().includes(query.toLowerCase());
  });
  const getDisplayValue = () => query || options.find(option => option.value === value)?.label || '';
  const handleOnChange = (_value: Props['value']) => {
    const option = options.find(option => option.value === _value);
    if (option) {
      setQuery(option.label);
      onChange(option.value);
    }
  };
  const handleQueryChange = (_query: string) => {
    setQuery(_query);
    if (_query === '') onChange(undefined as any);
  };
  return <div className={wrapperClassName} id={id} data-sentry-component="Combobox" data-sentry-source-file="Combobox.tsx">
      <HUICombobox as="div" value={query} onChange={handleOnChange} {...comboboxProps} data-sentry-element="HUICombobox" data-sentry-source-file="Combobox.tsx">
        <div className={containerClassName}>
          <HUICombobox.Label as="label" className="mb-2 block text-sm font-medium leading-3 text-gray-900" data-sentry-element="unknown" data-sentry-source-file="Combobox.tsx">
            {label}
            {required && ' *'}
          </HUICombobox.Label>
          <div className="relative rounded-md border border-gray-300">
            <HUICombobox.Button as="button" className={conditionalClasses('relative inset-y-0 flex w-full cursor-pointer justify-between rounded-md bg-white pr-8 text-left align-middle text-gray-900 focus:outline-none focus:ring-2 focus:ring-podi-primary sm:text-sm', {
            'focus-ring-0 pointer-events-none bg-gray-50 shadow-none ring-0': disabled,
            'pl-10': icon // Add padding to prevent overlap when icon is used
          }, buttonClassName)} data-sentry-element="unknown" data-sentry-source-file="Combobox.tsx">
              <HUICombobox.Input as="input" className="w-full rounded-md border-none pl-3 text-sm leading-6 text-gray-900 focus:ring-0" displayValue={() => getDisplayValue()} onChange={event => handleQueryChange(event.target.value)} placeholder={placeholder} data-sentry-element="unknown" data-sentry-source-file="Combobox.tsx" />
              <span className="pointer-events-none absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2">
                <ChevronUpDownIcon className={conditionalClasses('h-5 w-5 text-base', {
                'text-gray-400': disabled
              })} aria-hidden="true" data-sentry-element="ChevronUpDownIcon" data-sentry-source-file="Combobox.tsx" />
              </span>
            </HUICombobox.Button>
            {clearable && !disabled && !!getDisplayValue() && <button className="absolute inset-y-0 right-4 flex items-center px-4" onClick={() => handleQueryChange('')}>
                <XMarkIcon className={conditionalClasses('h-5 w-8 border-r-[1px] border-gray-300 text-base')} aria-hidden="true" />
              </button>}
            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="Transition" data-sentry-source-file="Combobox.tsx">
              <HUICombobox.Options as="div" className={conditionalClasses(`absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`, optionsClassName)} data-sentry-element="unknown" data-sentry-source-file="Combobox.tsx">
                {filteredOptions.length === 0 && query !== '' ? <div className="relative cursor-default select-none px-4 py-2 text-gray-700">Nothing found.</div> : (filteredOptions || []).map(option => <HUICombobox.Option as="div" key={`option_${option.value}`} className={({
                active,
                disabled
              }) => conditionalClasses(active ? 'cursor-pointer bg-podi-primary text-white' : disabled ? 'cursor-not-allowed text-slate-400' : 'text-gray-900', 'relative select-none py-2 pl-3 pr-9')} value={option.value} disabled={option.disabled}>
                      {({
                  selected,
                  active
                }) => <>
                          <span className={conditionalClasses(selected ? 'font-semibold' : 'font-normal', 'block')}>{option.label}</span>

                          {selected ? <span className={conditionalClasses(active ? 'text-white' : 'text-podi-primary', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span> : null}
                        </>}
                    </HUICombobox.Option>)}
              </HUICombobox.Options>
            </Transition>
          </div>
        </div>
      </HUICombobox>
      {fieldError && <p className="p-1 text-xs text-red-500">{fieldError.message}</p>}
    </div>;
}