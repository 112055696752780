import { conditionalClasses } from '@/utils/tailwind';
import Link, { type LinkProps } from 'next/link';
import type { FC } from 'react';
interface CustomLinkProps extends LinkProps {
  children?: React.ReactNode;
  href: string;
  id: string;
  isButton?: boolean;
  linkClasses?: string;
  prefetch?: boolean;
  replace?: boolean;
  target?: string;
  text?: string;
  wrapperClasses?: string;
}
export const CustomLink: FC<CustomLinkProps> = ({
  children,
  href,
  id,
  linkClasses,
  prefetch,
  replace,
  target,
  text,
  wrapperClasses,
  ...props
}) => {
  return <span id={id} className={wrapperClasses} data-sentry-component="CustomLink" data-sentry-source-file="CustomLink.tsx">
      <Link id={id} href={href} target={target} prefetch={prefetch} className={conditionalClasses('link focus:border-1 font-medium text-podi-primary duration-300 hover:text-podi-primary-hover hover:underline focus:outline-podi-primary', linkClasses)} replace={replace} {...props} data-sentry-element="Link" data-sentry-source-file="CustomLink.tsx">
        {text} {children}
      </Link>
    </span>;
};