import { type AchillesUser } from '@/schemas/users';
import { conditionalClasses } from '@/utils/tailwind';
import { Combobox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Avatar } from './Avatar';
import { Chip } from './Chip';
import { FormLabel } from './FormLabel';
export interface SearchableMultiSelectWImageQueryInput {
  searchWord: string;
  skip: number;
  take: number;
}
interface SearchableMultiSelectWImageProps {
  users: AchillesUser[];
  placeholder: string;
  label?: string;
  selectedUsers: AchillesUser[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<AchillesUser[]>>;
  setQueryInput: React.Dispatch<React.SetStateAction<SearchableMultiSelectWImageQueryInput>>;
  queryInput: SearchableMultiSelectWImageQueryInput;
}
export const SearchableMultiSelectWImage: React.FC<SearchableMultiSelectWImageProps> = ({
  users,
  placeholder,
  label,
  selectedUsers,
  setSelectedUsers,
  setQueryInput,
  queryInput
}) => {
  const usersList = users.filter(user => selectedUsers && !selectedUsers.some(selectedUser => selectedUser.email === user.email));
  const filteredUsers = usersList;
  const handleSelectUser = (user: any) => {
    if (selectedUsers.some(selectedUser => selectedUser?.email === user?.email)) {
      // gulp
      // setSelectedUsers(selectedUsers.filter(selectedUser => selectedUser.email !== user.email));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };
  const removeSelectedUser = (userEmail: string) => {
    setSelectedUsers(selectedUsers.filter(user => user.email !== userEmail));
  };
  const handleSearchWorkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    queryInput.searchWord = e?.target?.value;
    const updated = {
      take: 5,
      skip: 0,
      ['searchWord']: queryInput.searchWord
    };
    setQueryInput(updated);
  };
  return <div data-sentry-component="SearchableMultiSelectWImage" data-sentry-source-file="SearchableMultiSelectWImage.tsx">
      {label && <FormLabel>{label}</FormLabel>}
      <div className="relative mt-1">
        <Combobox as="div" value={selectedUsers} onChange={usr => {
        handleSelectUser(usr);
      }} data-sentry-element="Combobox" data-sentry-source-file="SearchableMultiSelectWImage.tsx">
          <Combobox.Input as="input" className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 focus:border-indigo-500 focus:ring-indigo-500" onChange={handleSearchWorkChange} displayValue={(user: AchillesUser) => user.email} placeholder={placeholder} data-sentry-element="unknown" data-sentry-source-file="SearchableMultiSelectWImage.tsx" />
          <div className="mt-2 flex flex-wrap gap-1 text-xs">
            {selectedUsers.map(user => <Chip key={user.email} email={user.email} name={user.firstName} image={user.avatarUrl} onClick={() => {
            removeSelectedUser(user.email);
          }} />)}
          </div>
          {filteredUsers.length > 0 && <Combobox.Options as="div" className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {filteredUsers.map(user => <Combobox.Option as="div" key={user.email} value={user} className={({
            active
          }) => conditionalClasses('relative cursor-pointer select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')}>
                  {({
              active,
              selected
            }) => <>
                      <div className="flex items-center">
                        <Avatar image={user.avatarUrl} name={user.firstName} />
                        <span className={conditionalClasses('ml-3 truncate', selected ? 'font-semibold' : 'font-normal')}>{user.email}</span>
                      </div>
                      {selected && <span className={conditionalClasses('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600')}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>}
                    </>}
                </Combobox.Option>)}
            </Combobox.Options>}
        </Combobox>
      </div>
    </div>;
};