import { conditionalClasses } from '@/utils/tailwind';
import React, { type ReactNode } from 'react';
interface CounterBoxProps {
  counter: string | number | undefined | null;
  label?: string;
  className?: string;
  icon?: ReactNode;
}
export const CounterBox: React.FC<CounterBoxProps> = ({
  counter,
  label,
  className,
  icon
}) => {
  return <div className={conditionalClasses('inline-flex items-center rounded-full px-2 py-1 pb-2 pl-3 pr-3 pt-2 text-sm font-medium ring-1 ring-inset', className)} data-sentry-component="CounterBox" data-sentry-source-file="CounterBox.tsx">
      {icon && <div className="mr-2 h-5 w-5">{icon}</div>}
      <p>
        {label && `${label}: `}
        {counter}{' '}
      </p>
    </div>;
};