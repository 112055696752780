import Loading from '@/(dashboard)/Loading';
import { type Dispatch, type SetStateAction } from 'react';
import { ActionButton } from './ActionButton';
import { ModalDialog } from './ModalDialog';
type ConfirmActionModalType = {
  handleAgree: () => void;
  handleClose: () => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  text: string;
  isSaving: boolean;
  parentIsModal?: boolean;
};
export const ConfirmActionModal: React.FC<ConfirmActionModalType> = ({
  handleAgree,
  handleClose,
  open,
  setOpen,
  title,
  text,
  isSaving
}) => {
  return <ModalDialog className="!w-3/12" title={title} isOpen={open} id="popup" setIsOpen={setOpen} parentIsModal={true} data-sentry-element="ModalDialog" data-sentry-component="ConfirmActionModal" data-sentry-source-file="ConfirmActionModal.tsx">
      <p>{text}</p>

      {isSaving ? <div className="flex w-full justify-end">
          <Loading className="h-7 w-6 text-podi-primary" />
        </div> : null}
      <div className="mt-5 flex items-center justify-end gap-5">
        <ActionButton id="yes" onClick={handleAgree} isDisabled={isSaving} className="mx-0 w-auto px-3 py-3 disabled:bg-podi-primary-disabled" text="Yes" data-sentry-element="ActionButton" data-sentry-source-file="ConfirmActionModal.tsx" />
        <ActionButton id="no" onClick={handleClose} isDisabled={isSaving} className="mx-0 w-auto px-3 py-3 disabled:bg-podi-primary-disabled" text="No" data-sentry-element="ActionButton" data-sentry-source-file="ConfirmActionModal.tsx" />
      </div>
    </ModalDialog>;
};