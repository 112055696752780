import { conditionalClasses } from '@/utils/tailwind';
import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import { FormLabel } from './FormLabel';
import { Loading } from './Loading';
import { OptionChip } from './OptionChip';
export interface SearchableMultiSelectQueryInput {
  searchWord: string;
  skip: number;
  take: number;
}
export interface SearchableMultiSelectOption {
  id: number;
  description: string;
  data?: any;
}
interface SearchableMultiSelectProps {
  options: SearchableMultiSelectOption[];
  placeholder: string;
  label?: string;
  selectedOptions: SearchableMultiSelectOption[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<SearchableMultiSelectOption[]>>;
  setQueryInput: React.Dispatch<React.SetStateAction<SearchableMultiSelectQueryInput>>;
  queryInput: SearchableMultiSelectQueryInput;
  isSingleSelect: boolean;
  itemsCount: number;
  serverSidePagination: boolean;
}
export const SearchableMultiSelect: React.FC<SearchableMultiSelectProps> = ({
  options,
  placeholder,
  label,
  selectedOptions,
  setSelectedOptions,
  setQueryInput,
  queryInput,
  isSingleSelect,
  itemsCount,
  serverSidePagination
}) => {
  const OPTIONS_PER_PAGE = !serverSidePagination ? itemsCount : queryInput.take;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const optionsList = options.filter(option => selectedOptions && !selectedOptions.some(selectedOption => selectedOption.id === option.id));
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const pageCount = Math.ceil(itemsCount / OPTIONS_PER_PAGE);
  const [paginatedOptions, setPaginatedOptions] = useState<SearchableMultiSelectOption[]>(optionsList);
  const goToNextPage = () => {
    setCurrentPage(current => Math.min(current + 1, pageCount));
  };
  const goToPreviousPage = () => {
    setCurrentPage(current => Math.max(current - 1, 1));
  };
  const handleSelectedOption = (option: any) => {
    if (selectedOptions.some(selectedOption => selectedOption.id === option?.id)) {
      // TODO: something or change logic
    } else {
      if (isSingleSelect) {
        setSelectedOptions([option]);
      } else {
        setSelectedOptions([...selectedOptions, option]);
      }
    }
  };
  const removeSelectedOption = (id: any) => {
    setSelectedOptions(selectedOptions.filter(option => option.id !== id));
  };
  const handleSearchWorkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    queryInput.searchWord = e?.target?.value;
    const updated = {
      take: OPTIONS_PER_PAGE,
      skip: 0,
      ['searchWord']: queryInput.searchWord
    };
    setCurrentPage(1);
    setIsLoading(true);
    setQueryInput(updated);
  };
  useEffect(() => {
    setIsLoading(true);
    const updated = {
      ...queryInput,
      take: OPTIONS_PER_PAGE,
      skip: OPTIONS_PER_PAGE * (currentPage - 1)
    };
    setQueryInput(updated);
  }, [currentPage]);
  useEffect(() => {
    setIsLoading(false);
    if (serverSidePagination) {
      setPaginatedOptions(optionsList);
    }
  }, [options]);
  useEffect(() => {
    if (!serverSidePagination) {
      if (queryInput.searchWord !== '') {
        setPaginatedOptions(paginatedOptions.filter((v: SearchableMultiSelectOption) => v.description.toLowerCase().includes(queryInput.searchWord.toLowerCase())));
      } else {
        setPaginatedOptions(optionsList);
      }
      setIsLoading(false);
    }
  }, [queryInput]);
  return <div data-sentry-component="SearchableMultiSelect" data-sentry-source-file="SearchableMultiSelect.tsx">
      {label && <FormLabel>{label}</FormLabel>}
      <div className="relative mt-1">
        <Combobox as="div" value={selectedOptions} onChange={option => {
        handleSelectedOption(option);
      }} data-sentry-element="Combobox" data-sentry-source-file="SearchableMultiSelect.tsx">
          <div className="flex items-center">
            <Combobox.Input as="input" className="w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-10 focus:border-podi-primary focus:ring-indigo-500" onChange={handleSearchWorkChange} displayValue={(option: SearchableMultiSelectOption) => option.description} placeholder={placeholder} data-sentry-element="unknown" data-sentry-source-file="SearchableMultiSelect.tsx" />
            <Combobox.Button as="button" className="absolute bottom-0 right-0 top-0 flex items-center justify-center px-2" onClick={() => setIsOpen(!isOpen)} data-sentry-element="unknown" data-sentry-source-file="SearchableMultiSelect.tsx">
              {isOpen ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
            </Combobox.Button>
          </div>
          <div className="mt-2 flex flex-wrap gap-1 text-xs">
            {selectedOptions.map(option => <OptionChip key={option.id} id={option.id} description={option.description} onClick={() => {
            removeSelectedOption(option.id);
          }} />)}
          </div>
          {optionsList.length > 0 && <Combobox.Options as="div" className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {pageCount > 1 && <div className="sticky top-0 z-20 flex items-center justify-between border-b bg-white p-2 px-4" style={{
            marginTop: '-1px'
          }}>
                  <button onClick={goToPreviousPage} disabled={currentPage === 1 || isLoading}>
                    &#8592; Prev
                  </button>
                  <span>
                    Page {currentPage} of {pageCount}
                  </span>
                  <button onClick={goToNextPage} disabled={currentPage === pageCount || isLoading}>
                    Next &#8594;
                  </button>
                </div>}

              {isLoading && <div className="flex h-40 items-center justify-center">
                  <Loading className="h-7 w-6 text-black" />
                </div>}
              {!isLoading && paginatedOptions.map(option => <Combobox.Option as="div" key={option.id} value={option} className={({
            active
          }) => conditionalClasses('relative cursor-pointer select-none py-2 pl-3 pr-9', active ? 'bg-podi-primary text-white' : 'text-gray-900')}>
                    {({
              active,
              selected
            }) => <>
                        <div className="flex items-center">
                          {/*
                                                <Avatar image={user.avatarUrl} name={user.firstName}/>
                           */}
                          <span className={conditionalClasses('ml-3 truncate', selected ? 'font-semibold' : 'font-normal')}>{option.description}</span>
                        </div>
                        {selected && <span className={conditionalClasses('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600')}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>}
                      </>}
                  </Combobox.Option>)}
            </Combobox.Options>}
        </Combobox>
      </div>
    </div>;
};