import { type FC } from 'react';
interface RadioButtonItem {
  label: string;
  selected?: boolean;
  onClick?: () => void;
}
interface RadioButtonsProps {
  label?: string;
  items: RadioButtonItem[];
  name: string;
  className?: string;
  arrangement?: 'horizontal' | 'vertical';
}
export const RadioButton: FC<RadioButtonsProps> = ({
  label,
  items,
  name,
  arrangement,
  className
}) => {
  return <fieldset className={className} data-sentry-component="RadioButton" data-sentry-source-file="RadioButton.tsx">
      <label className="flex items-center">{label}</label>

      <div className={arrangement == 'vertical' ? 'flex flex-row' : ''}>
        {items?.map((item, key) => {
        return <div key={key}>
              <input className={'mb-1'} type="radio" id={'radio' + key + item.label} name={name} defaultChecked={item.selected} onClick={item.onClick} />
              <label className={'ml-2 mr-5'} htmlFor={'radio' + key + item.label}>
                {item.label}
              </label>
            </div>;
      })}
      </div>
    </fieldset>;
};