import { conditionalClasses } from '@/utils/tailwind';
import { type FC } from 'react';
interface CardProps extends React.ComponentPropsWithoutRef<'article'> {
  id: string;
  cardClasses?: string;
  contentClasses?: string;
  headerClasses?: string;
  bodyClasses?: string;
  footerClasses?: string;
  cardHeader?: React.ReactNode;
  cardBody?: React.ReactNode;
  cardFooter?: React.ReactNode;
}
export const Card: FC<CardProps> = ({
  id,
  className,
  contentClasses,
  headerClasses,
  bodyClasses,
  footerClasses,
  cardHeader,
  cardBody,
  cardFooter,
  children,
  ...props
}) => {
  return <article id={id} className={conditionalClasses('card sm:px-10l relative bg-white px-6 py-10 shadow-lg ring-1 ring-gray-900/5 sm:max-w-lg sm:rounded-lg', className)} {...props} data-sentry-component="Card" data-sentry-source-file="Card.tsx">
      <div className={conditionalClasses('card-content mx-auto', contentClasses)}>
        {cardHeader && <header className={conditionalClasses('card-header', headerClasses)}>{cardHeader}</header>}
        <div className={conditionalClasses('card-body', bodyClasses)}>
          {cardBody}
          {children}
        </div>
        {cardFooter && <footer className={conditionalClasses('card-footer', footerClasses)}>{cardFooter}</footer>}
      </div>
    </article>;
};