'use-client';

import { type FC } from 'react';
interface SkeletonLoadingProps {
  className?: string;
  linesLength: number;
}
export const SkeletonLoading: FC<SkeletonLoadingProps> = ({
  className,
  linesLength = 1
}) => {
  const newArray: number[] = new Array(linesLength).fill(2);
  return <div className={` flex flex-col ${className}`} data-sentry-component="SkeletonLoading" data-sentry-source-file="SkeletonLoading.tsx">
      <div className="h-10 w-full animate-pulse bg-gray-300"></div>

      {newArray.map((_item, key) => {
      return <div key={key} className="mt-4 h-7 w-full animate-pulse bg-gray-200"></div>;
    })}
    </div>;
};