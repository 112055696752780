import { type AchillesUser } from '@/schemas/users';
import { conditionalClasses } from '@/utils/tailwind';
import { Combobox } from '@headlessui/react';
import { CheckIcon, XCircleIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState, type KeyboardEvent } from 'react';
import { Avatar } from './Avatar';
import { Chip } from './Chip';
import { FormLabel } from './FormLabel';
interface SelectWImageProps {
  users: AchillesUser[];
  placeholder: string;
  label?: string;
  selectedUser?: AchillesUser | null;
  setSelectedUser: (user: AchillesUser | null) => void;
  clearSelectedUser: () => void;
  isEditable?: boolean;
}
export const SelectWImage: React.FC<SelectWImageProps> = ({
  users,
  placeholder,
  label,
  selectedUser,
  setSelectedUser,
  clearSelectedUser,
  isEditable = true
}) => {
  const [query, setQuery] = useState('');
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const filteredPeople = query.trim() === '' ? users : users.filter(person => (person.firstName?.trim().toLowerCase().replace(/\s+/g, '') + person.lastName?.trim().toLowerCase().replace(/\s+/g, '')).includes(query.toLowerCase().replace(/\s+/g, '')));
  const handleKeyboardEvent = (event: KeyboardEvent<HTMLInputElement>) => {
    const keyPress = event.key;
    if (keyPress === 'Backspace' || keyPress === 'Delete') {
      setSelectedUser(null);
      return;
    }
  };
  if (!mounted) return null;
  return <Combobox as="div" value={selectedUser || ''} onChange={setSelectedUser} data-sentry-element="Combobox" data-sentry-component="SelectWImage" data-sentry-source-file="SelectWImage.tsx">
      {label && <FormLabel> {label}</FormLabel>}
      <div className="relative">
        <Combobox.Input as="input" className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-podi-primary focus:outline-none focus:ring-1 focus:ring-podi-primary sm:text-md" defaultValue={(user: AchillesUser) => user?.email} placeholder={placeholder} onChange={event => setQuery(event.target.value)} onKeyDown={event => handleKeyboardEvent(event)} readOnly={!isEditable} data-sentry-element="unknown" data-sentry-source-file="SelectWImage.tsx" />
        {selectedUser ? <div className="absolute left-1 top-1">
            <Chip name={selectedUser.firstName} image={selectedUser.avatarUrl} email={selectedUser.email} />
          </div> : ''}
        {selectedUser && clearSelectedUser ? <div onClick={clearSelectedUser} className="absolute inset-y-0 right-0 flex cursor-pointer items-center rounded-r-md px-2 text-gray-400 focus:outline-none" title="Clear selection">
            <XCircleIcon className="h-5 w-5 text-gray-400" />
          </div> : <Combobox.Button as="button" className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 text-gray-400 focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
            </svg>
          </Combobox.Button>}

        {users?.length > 0 && <Combobox.Options as="div" className="absolute z-20 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map(user => <span key={user.email}>
                <Combobox.Option as="div" value={user} className={({
            active
          }) => conditionalClasses('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-blue-500 text-white' : 'text-gray-900')}>
                  {({
              active,
              selected
            }) => <>
                      <div className="flex cursor-pointer items-center">
                        <div className="">
                          <Avatar image={user.avatarUrl} name={user.firstName} />
                        </div>
                        <div className="flex flex-col">
                          <span className={conditionalClasses('ml-3 truncate text-sm font-medium', selected && 'font-semibold')}>{user.email}</span>
                          <span className={conditionalClasses('ml-3 truncate text-sm', active ? 'text-gray-200' : 'text-gray-500')}>
                            {user.firstName} {user.lastName}
                          </span>
                        </div>
                      </div>

                      {selectedUser?.email === user.email && <span className={conditionalClasses('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-blue-500')}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>}
                    </>}
                </Combobox.Option>
              </span>)}
          </Combobox.Options>}
      </div>
    </Combobox>;
};