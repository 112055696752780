import { conditionalClasses } from '@/utils/tailwind';
import { XCircleIcon } from '@heroicons/react/20/solid';
import React from 'react';
interface ChipProps {
  id: number | string;
  description?: string | null;
  className?: string;
  onClick?: () => void;
}
export const OptionChip: React.FC<ChipProps> = ({
  description,
  className,
  onClick
}) => {
  return <div className={conditionalClasses('flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-2 text-white', className)} data-sentry-component="OptionChip" data-sentry-source-file="OptionChip.tsx">
      <p>{description}</p>
      {onClick && <div onClick={onClick} className="">
          <XCircleIcon className="h-5 w-5" />
        </div>}
    </div>;
};