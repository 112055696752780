import { conditionalClasses } from '@/utils/tailwind';
import { ReactNode } from 'react';
export interface TagProps {
  type: 'lightblue' | 'darkblue' | 'danger' | 'warning' | 'success' | 'info';
  className?: string;
  children: ReactNode;
}
const TypeMap: Record<TagProps['type'], string> = {
  lightblue: 'bg-blue-100 border-blue-200',
  darkblue: 'bg-blue-500 border-blue-600',
  danger: 'bg-red-500 border-red-600',
  warning: 'bg-yellow-200 border-yellow-300',
  success: 'bg-green-500 border-green-600',
  info: 'bg-blue-200 border-blue-300'
};
export function Tag({
  type = 'darkblue',
  className,
  children
}: TagProps) {
  return <span className={conditionalClasses(TypeMap[type], 'inline-block rounded-md border p-2 text-sm font-semibold text-black', className)} data-sentry-component="Tag" data-sentry-source-file="Tag.tsx">{children}</span>;
}