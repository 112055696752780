import { conditionalClasses } from '@/utils/tailwind';
import type { FC } from 'react';
interface ContainerProps extends React.ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode;
}
export const Container: FC<ContainerProps> = ({
  className,
  children,
  ...props
}) => {
  return <div className={conditionalClasses('mx-auto px-4 sm:px-6 lg:px-8', className)} {...props} data-sentry-component="Container" data-sentry-source-file="Container.tsx">
      {children}
    </div>;
};