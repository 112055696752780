import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useState, type Dispatch, type SetStateAction } from 'react';
import { useController, type FieldValues, type UseControllerProps, type UseFormResetField } from 'react-hook-form';
interface ControlledComboBoxProps<T, K extends keyof T> {
  options: T[];
  containerClassName?: string;
  name: string;
  resetField: UseFormResetField<any>;
  formId?: string;
  searchByKey: K;
  formValueKey: K;
  stateValue: T | '';
  inputClassName?: string;
  label?: string;
  setStateValue: Dispatch<SetStateAction<T | '' | any>>;
}
type ControllerProps<C extends FieldValues> = UseControllerProps<C>;
export function ControlledComboBox<T, K extends keyof T, C extends FieldValues>(props: ControllerProps<C> & ControlledComboBoxProps<T, K>) {
  const {
    containerClassName,
    formId,
    options,
    searchByKey,
    formValueKey,
    stateValue,
    inputClassName,
    label,
    setStateValue,
    resetField,
    ...controllerProps
  } = props;
  const {
    field,
    fieldState
  } = useController(controllerProps);
  const [query, setQuery] = useState('');
  const displayInputValue = () => {
    if (query) return query;
    if (typeof stateValue === 'object' && stateValue && searchByKey in stateValue) return stateValue[searchByKey] as string | number;
    return '';
  };
  const filteredOptions = query === '' ? options : options.filter(option => {
    if (typeof option === 'object' && option !== null && searchByKey in option) {
      const optionVal = option[searchByKey] as string;
      return optionVal.toLowerCase().includes(query.toLowerCase());
    }
    return [];
  });
  return <>
      <Combobox as="div" value={stateValue} onChange={option => {
      if (option === '') return;
      field.onChange(option[formValueKey]); // data send back to hook form
      if (setStateValue) setStateValue(option); // UI state
    }} data-sentry-element="Combobox" data-sentry-source-file="ControlledComboBox.tsx">
        <Combobox.Label as="label" className="mt-6 block text-sm font-medium leading-6 text-gray-900" data-sentry-element="unknown" data-sentry-source-file="ControlledComboBox.tsx">
          {label}
        </Combobox.Label>
        <div className="relative mt-3">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-podi-primary sm:text-sm">
            <Combobox.Button as="button" className="inset-y-0 right-0 flex w-full items-center pr-2" data-sentry-element="unknown" data-sentry-source-file="ControlledComboBox.tsx">
              <Combobox.Input as="input" name={field.name} value={displayInputValue()} ref={field.ref} onBlur={field.onBlur} className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0" onChange={event => {
              if (field?.value) {
                resetField(field.name);
                setStateValue('');
              }
              setQuery(event.target.value);
            }} placeholder="Start typing to search..." data-sentry-element="unknown" data-sentry-source-file="ControlledComboBox.tsx" />
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" data-sentry-element="ChevronUpDownIcon" data-sentry-source-file="ControlledComboBox.tsx" />
            </Combobox.Button>
          </div>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery('')} data-sentry-element="Transition" data-sentry-source-file="ControlledComboBox.tsx">
            <Combobox.Options as="div" className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" data-sentry-element="unknown" data-sentry-source-file="ControlledComboBox.tsx">
              {filteredOptions.length === 0 && query !== '' ? <div className="relative cursor-default select-none px-4 py-2 text-gray-700">Nothing found.</div> : filteredOptions.map((option, i) => {
              return <Combobox.Option as="div" key={`${option?.[searchByKey]}` || i} className={({
                active
              }) => `relative cursor-default py-2 pl-10 pr-4 ${active ? 'bg-podi-primary text-white' : 'text-gray-900'}`} value={option}>
                      {({
                  selected,
                  active
                }) => <>
                          <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{`${option[searchByKey]}`}</span>
                          {selected ? <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-podi-primary'}`}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span> : null}
                        </>}
                    </Combobox.Option>;
            })}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {fieldState?.error && <p className="ml-2 mt-4 text-sm text-red-600" id="email-error">
          {fieldState?.error.message || 'Please select a value.'}
        </p>}
    </>;
}