import { conditionalClasses } from '@/utils/tailwind';
import { type ServersideDataQueryOptions } from '@/utils/types';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, InboxIcon } from '@heroicons/react/20/solid';
import { flexRender, type HeaderGroup, type PaginationState, type Row, type SortingState, type Table } from '@tanstack/react-table';
import { isEqual } from 'lodash';
import { memo, useState } from 'react';
import { convertValuesToOptions } from './Select';
import { TextInput, type TextInputProps } from './TextInput';
export type TableParams = Pick<ServersideDataQueryOptions, 'ItemsPerPage' | 'Page' | 'Search' | 'SortBy' | 'SortDesc'>;
export type AddlParams = Omit<ServersideDataQueryOptions, 'ItemsPerPage' | 'Page' | 'Search' | 'SortBy' | 'SortDesc'>;
type InitialTableParam = SortingState | PaginationState | string | undefined;
export const evalInitialTableParam = (defaultParam?: InitialTableParam, searchParam?: InitialTableParam): InitialTableParam => {
  if (searchParam && !isEqual(searchParam, defaultParam)) return searchParam;
  return defaultParam;
};
export function TableLoaderProgressBar() {
  return <tbody className="w-full bg-white before:absolute before:left-[-50%] before:h-[3px] before:w-1/3 before:animate-line before:overflow-hidden before:rounded-lg before:bg-podi-primary" data-sentry-component="TableLoaderProgressBar" data-sentry-source-file="Table.utils.tsx"></tbody>;
}
interface SearchInputProps extends Omit<TextInputProps, 'onChange'> {
  onChange: (value: string) => void;
  query?: string;
}
export function SearchInput({
  query: _query = '',
  onChange,
  className,
  inputClassName,
  ...otherTextInputProps
}: SearchInputProps): JSX.Element {
  const [query, setQuery] = useState(_query);
  const handleSetValue = (value: string = '') => {
    setQuery(value);
    onChange(value);
  };
  return <TextInput type="search" className={conditionalClasses('h-9 w-full sm:w-64', className)} inputClassName={conditionalClasses('h-9', inputClassName)} value={query} onChange={e => handleSetValue(e.target.value)} placeholder="Search" {...otherTextInputProps} data-sentry-element="TextInput" data-sentry-component="SearchInput" data-sentry-source-file="Table.utils.tsx" />;
}
export const SearchInputMemo = memo(SearchInput);
export function TableLoaderSkeleton({
  pageSize,
  colSize
}: {
  pageSize: number;
  colSize: number;
}) {
  return <tbody data-sentry-component="TableLoaderSkeleton" data-sentry-source-file="Table.utils.tsx">
      {Array.from({
      length: pageSize
    }).map((_, rowIndex) => <tr key={'loader' + rowIndex}>
          <td colSpan={colSize}>
            <div className={`h-10 w-full animate-pulse ${rowIndex % 2 === 0 ? 'bg-gray-150' : 'bg-gray-100'}`}></div>
          </td>
        </tr>)}
    </tbody>;
}
export function TableEmpty({
  colSize
}: {
  colSize: number;
}) {
  return <tr data-sentry-component="TableEmpty" data-sentry-source-file="Table.utils.tsx">
      <td colSpan={colSize}>
        <div className="flex flex-col items-center justify-center gap-2 p-6">
          <InboxIcon className="h-10 w-10" data-sentry-element="InboxIcon" data-sentry-source-file="Table.utils.tsx" />
          <strong>No Records Found</strong>
        </div>
      </td>
    </tr>;
}
export function TableHeader({
  headerGroups
}: {
  headerGroups: HeaderGroup<any>[];
}) {
  return <thead data-sentry-component="TableHeader" data-sentry-source-file="Table.utils.tsx">
      {headerGroups.map(headerGroup => <tr key={headerGroup.id}>
          {headerGroup.headers.map(header => {
        return <th key={header.id} colSpan={header.colSpan} style={{
          width: header.column.getSize()
        }} className="px-3 py-2 text-left text-tData font-semibold leading-4 text-gray-900">
                {!header.isPlaceholder && <div className={conditionalClasses('flex-row items-center', header.column.getCanSort() ? 'flex cursor-pointer select-none flex-row gap-1' : '')} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getIsSorted() === 'asc' ? <ChevronUpIcon className="h-4" /> : header.column.getIsSorted() === 'desc' ? <ChevronDownIcon className="h-4" /> : null}
                  </div>}
              </th>;
      })}
        </tr>)}
    </thead>;
}
export function TableRows({
  rows,
  rowClassesFn
}: {
  rows: Row<any>[];
  rowClassesFn?: (item: any) => string;
}) {
  return (rows || []).map(row => {
    return <tr key={row.id} className={conditionalClasses('transition-all even:bg-gray-50 hover:bg-gray-200', rowClassesFn?.(row) ?? '')}>
        {row.getVisibleCells().map(cell => {
        return <td key={cell.id} style={{
          width: cell.column.getSize()
        }} className="px-3 py-2 text-sm">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>;
      })}
      </tr>;
  });
}
export function TableFooter({
  table
}: {
  table: Table<any>;
}) {
  const totalCount = table.getRowCount();
  const startCount = totalCount ? table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1 : 0;
  const endCount = totalCount ? Math.min(startCount + table.getState().pagination.pageSize - 1, table.getRowCount()) : 0;
  return <footer className="flex flex-row items-center justify-end p-4" data-sentry-component="TableFooter" data-sentry-source-file="Table.utils.tsx">
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-1">
          <label className="text-sm">
            {startCount.toLocaleString()} - {endCount.toLocaleString()} of {totalCount.toLocaleString()}
          </label>
        </div>
        <div className="flex items-center gap-1">
          <button className="text-podi-primary-dark disabled:opacity-20" onClick={() => table.firstPage()} disabled={!table.getCanPreviousPage()}>
            <ChevronDoubleLeftIcon className="h-6" data-sentry-element="ChevronDoubleLeftIcon" data-sentry-source-file="Table.utils.tsx" />
          </button>
          <button className="text-podi-primary-dark disabled:opacity-20" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
            <ChevronLeftIcon className="h-6" data-sentry-element="ChevronLeftIcon" data-sentry-source-file="Table.utils.tsx" />
          </button>
          <button className="text-podi-primary-dark disabled:opacity-20" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            <ChevronRightIcon className="h-6" data-sentry-element="ChevronRightIcon" data-sentry-source-file="Table.utils.tsx" />
          </button>
          <button className="text-podi-primary-dark disabled:opacity-20" onClick={() => table.lastPage()} disabled={!table.getCanNextPage()}>
            <ChevronDoubleRightIcon className="h-6" data-sentry-element="ChevronDoubleRightIcon" data-sentry-source-file="Table.utils.tsx" />
          </button>
        </div>
        <div className="flex items-center gap-1">
          <select className="relative cursor-pointer rounded-md border border-gray-300 bg-white py-2 text-left text-gray-900 focus:outline-none focus:ring-2 focus:ring-podi-primary sm:text-sm sm:leading-6" value={table.getState().pagination.pageSize} onChange={e => table.setPageSize(Number(e.target.value))}>
            {convertValuesToOptions([10, 25, 50, 100]).map(option => <option key={String(option?.value)} value={Number(option.value)}>
                {option.label}
              </option>)}
          </select>
          <label className="text-sm">per page</label>
        </div>
      </div>
    </footer>;
}