'use client';

import { AuthProvider } from '@/hooks/useAuth';
import { ACTIVE_BROADCAST_CHANNEL_ID, REACT_QUERY_CHANNEL_NAME, type BroadcastChannelData } from '@/utils/broadcast-channel';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SessionProvider } from 'next-auth/react';
import { lazy, Suspense, useEffect, useState, type ReactNode } from 'react';
import { NotificationContainer } from '../components/shared/Notification';
import { queryClient } from '../utils/react-query';
import { ModalProvider } from './ModalProvider';
const ReactQueryDevtoolsProduction = lazy(() => import('@tanstack/react-query-devtools/build/modern/production.js').then(d => ({
  default: d.ReactQueryDevtools
})));
export default function Providers({
  children
}: {
  children: ReactNode;
}) {
  const [showDevtools, setShowDevtools] = useState(false);
  useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools(prev => !prev);
  }, []);
  useEffect(() => {
    if (!queryClient) return;
    const channel = new BroadcastChannel(REACT_QUERY_CHANNEL_NAME);
    channel.onmessage = event => {
      const data = event.data as BroadcastChannelData;
      if (data.channelId !== ACTIVE_BROADCAST_CHANNEL_ID && data.type === 'invalidate_all') {
        queryClient.invalidateQueries();
      }
    };
    return () => channel.close();
  }, [queryClient]);
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="Providers" data-sentry-source-file="Providers.tsx">
      <SessionProvider refetchInterval={5 * 60} data-sentry-element="SessionProvider" data-sentry-source-file="Providers.tsx">
        <AuthProvider data-sentry-element="AuthProvider" data-sentry-source-file="Providers.tsx">
          <ModalProvider data-sentry-element="ModalProvider" data-sentry-source-file="Providers.tsx">{children}</ModalProvider>
          <NotificationContainer data-sentry-element="NotificationContainer" data-sentry-source-file="Providers.tsx" />
          <ReactQueryDevtools initialIsOpen={false} data-sentry-element="ReactQueryDevtools" data-sentry-source-file="Providers.tsx" />
          {showDevtools && <Suspense fallback={null}>
              <ReactQueryDevtoolsProduction />
            </Suspense>}
        </AuthProvider>
      </SessionProvider>
    </QueryClientProvider>;
}