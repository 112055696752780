import { conditionalClasses } from '@/utils/tailwind';
import { XCircleIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Avatar } from './Avatar';
interface ChipProps {
  email: string;
  image?: string | null;
  name: string;
  className?: string;
  onClick?: () => void;
}
export const Chip: React.FC<ChipProps> = ({
  image,
  name,
  email,
  className,
  onClick
}) => {
  return <div className={conditionalClasses('flex cursor-pointer items-center gap-1 rounded bg-gray-400 px-2 text-white', className)} data-sentry-component="Chip" data-sentry-source-file="Chip.tsx">
      <Avatar className="h-8 w-8" image={image} name={name} data-sentry-element="Avatar" data-sentry-source-file="Chip.tsx" />
      <p>{email}</p>
      {onClick && <div onClick={onClick} className="">
          <XCircleIcon className="h-5 w-5" />
        </div>}
    </div>;
};