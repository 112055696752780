import { type Dispatch, type SetStateAction } from 'react';
import { ModalDialog } from './ModalDialog';
type ConfirmActionModalType = {
  handleClose: () => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  text: string;
  isSaving: boolean;
  delayInMS: number;
};
export const TimedConfirmModal: React.FC<ConfirmActionModalType> = ({
  handleClose,
  open,
  setOpen,
  title,
  text,
  isSaving,
  delayInMS = 3000
}) => {
  setTimeout(() => {
    handleClose();
  }, delayInMS);
  return <ModalDialog className="!w-3/12" title={title} isOpen={open} id="popup" setIsOpen={setOpen} data-sentry-element="ModalDialog" data-sentry-component="TimedConfirmModal" data-sentry-source-file="TimedConfirmModal.tsx">
      <div className={`${typeof text !== 'string' ? 'flex w-full justify-center' : ''}`}>
        <p>{text}</p>
      </div>
      <div className="mt-5 flex items-center justify-end gap-5">
        <button id="yes" onClick={handleClose} disabled={isSaving}>
          Confirm
        </button>
      </div>
    </ModalDialog>;
};