import { type FC } from 'react';
interface LineLoadingProps {
  className?: string;
  loadingText?: string;
}
export const LineLoading: FC<LineLoadingProps> = ({
  className,
  loadingText
}) => {
  return <>
      {loadingText && <p className="pb-2 text-center text-base font-semibold">{loadingText}</p>}
      <div className={`line-container relative cursor-pointer ${className}`}>
        <div className="absolute left-0 top-0 h-1 w-full overflow-hidden bg-white before:absolute before:left-[-50%] before:h-1.5 before:w-1/3 before:animate-line before:rounded-lg before:bg-podi-primary"></div>
      </div>
    </>;
};