import { conditionalClasses } from '@/utils/tailwind';
import React, { useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { useController, type FieldValues, type UseControllerProps } from 'react-hook-form';
import Datepicker from 'react-tailwindcss-datepicker';
import { FormLabel } from './FormLabel';
type ControlledDatePickerType = {
  containerClassName?: string;
  name: string;
  formId: string;
  placeHolderText: string;
  stateValue: string;
  inputClassName?: string;
  label?: string;
  labelId?: string;
  setStateValue: Dispatch<SetStateAction<string>>;
  icon?: React.ReactNode;
  minDate?: any;
  maxDate?: any;
  clearErrors: () => void;
};

/**
 * ControlledDatePicker used with React Hook Forms (RHF)
 * @example
 * name: input form name used in RHF
 * formId: html id
 * stateValue, setStateValue: external form state
 * control: control object from useForm
 * containerClassName?: container className
 * inputClassName?: input className
 * label?: option label
 * minDate, maxDate: set Min and Max date
 */
export const ControlledDatePicker = <T extends FieldValues,>(props: UseControllerProps<T> & ControlledDatePickerType) => {
  const {
    containerClassName,
    icon,
    minDate,
    maxDate,
    formId,
    inputClassName,
    placeHolderText,
    label,
    labelId,
    stateValue,
    clearErrors,
    setStateValue,
    ...controllerProps
  } = props;
  const required = controllerProps?.rules?.required;
  const disabled = controllerProps.disabled;
  const {
    fieldState
  } = useController(controllerProps);
  const {
    invalid,
    error
  } = fieldState;
  const [date, setDate] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null
  });
  useEffect(() => {
    setDate({
      startDate: stateValue ? stateValue : null,
      endDate: stateValue ? stateValue : null
    });
  }, [stateValue]);
  useEffect(() => {
    //date field filter (accepts only numbers and '/')
    if (formId) {
      // TODO handle the react way
      const elm = document.getElementById(formId) as HTMLInputElement;
      elm?.addEventListener('keypress', function (e) {
        const len = elm.value.length;
        if (e.keyCode < 47 || e.keyCode > 57) e.preventDefault();
        if (len !== 1 && e.keyCode == 47) e.preventDefault();
        if (len !== 3 && e.keyCode == 47) e.preventDefault();
        if (len === 2) elm.value += '/';
        if (len === 5) elm.value += '/';
      });
    }
  });
  const handleDateChange = (newDate: any) => {
    setDate(newDate);
    if (newDate?.startDate) setStateValue(newDate.startDate);
  };

  // TODO: is this needed?
  // const {} = useFormState(controllerProps);

  return <div className="flex w-full items-end" data-sentry-component="ControlledDatePicker" data-sentry-source-file="ControlledDatePicker.tsx">
      {icon}
      <div className="w-full items-center">
        <div className={`m-0 h-auto w-full resize-none bg-transparent p-0 text-lg focus:border-black focus:outline-none focus:ring-0 ${containerClassName}`}>
          {label && <FormLabel id={formId}>
              {label}
              {required ? ' *' : ''}
            </FormLabel>}
          <Datepicker inputClassName={conditionalClasses(error && invalid ? 'border-red-500 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500', `w-full mt-2 rounded-md px-3 py-2 text-gray-900 placeholder-gray-400 focus:bg-white focus:outline-none sm:text-sm`, inputClassName)} inputId={formId} displayFormat={'MM/DD/YYYY'} minDate={minDate} maxDate={maxDate} useRange={false} asSingle={true} value={date} onChange={date => {
          handleDateChange(date);
          if (error) clearErrors();
        }} disabled={disabled} data-sentry-element="Datepicker" data-sentry-source-file="ControlledDatePicker.tsx" />
        </div>
        {error && <p className="my-2 text-sm text-red-500">{error?.message}</p>}
      </div>
    </div>;
};