export const TabSelector = ({
  isActive,
  children,
  onClick
}: {
  isActive: boolean;
  children: React.ReactNode;
  onClick: () => void;
}) => <button className={`group inline-flex cursor-pointer items-center whitespace-nowrap border-b-2 px-6 py-4 text-sm font-medium leading-5 ${isActive ? 'focus:text-podi-primaryfocus:border-podi-primary border-podi-primary text-podi-primary focus:outline-none' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 focus:border-gray-300 focus:text-gray-600'}`} onClick={onClick} data-sentry-component="TabSelector" data-sentry-source-file="TabSelector.tsx">
    {children}
  </button>;