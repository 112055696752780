import { handleProviderAddress, handleProviderPhoneNumber } from '../modules/purchase-order/PurchaseOrderHelper';
interface ProviderLookupTableType<T> {
  providerList: any[]; // TODO Fix this
  handleProviderClick: (provider: T) => Promise<void>;
}
export function LookupProviderTable<T>({
  providerList,
  handleProviderClick
}: ProviderLookupTableType<T>) {
  return <div className="px-4 sm:px-6 lg:px-8" data-sentry-component="LookupProviderTable" data-sentry-source-file="LookupProviderTable.tsx">
      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Action
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    NPI
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    First Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Last Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Primary Practice Address
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Phone
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Taxonomy
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {providerList.map((provider, index) => <tr key={`${provider?.providerId}-${index}`}>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <button onClick={() => {
                    void handleProviderClick(provider);
                  }} className="button shadow-button w-max-fit rounded-md bg-podi-primary px-4 py-1 font-sans font-medium uppercase tracking-widest text-white duration-300 hover:bg-podi-primary-hover focus:outline-podi-primary focus:ring-2 focus:ring-podi-primary focus:ring-offset-2 focus:ring-offset-white disabled:bg-podi-primary-disabled disabled:shadow-none">
                        SELECT
                      </button>
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{provider?.number}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{provider?.firstName}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{provider?.lastName}</td>
                    <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">{handleProviderAddress(provider?.addresses)}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{handleProviderPhoneNumber(provider?.phoneNumbers)}</td>
                    <td className="whitespace-normal px-3 py-4 text-sm text-gray-500">{provider?.taxonomy?.description}</td>
                  </tr>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>;
}