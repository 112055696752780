'use client';

import { type TagProps } from '@/components/shared/Tag';
import BG_DARKBLUE from '@/public/images/background/darkblue.png';
import BG_LIGHTBLUE from '@/public/images/background/lightblue.png';
import BG_YELLOW from '@/public/images/background/yellow.png';
import { type StaticImageData } from 'next/image';

export enum Environment {
  LOCAL = 'LOCAL',
  DEV = 'DEV',
  UAT = 'UAT',
  PROD = 'PROD'
}

export const ENV: Environment = process.env.NEXT_PUBLIC_ENV as Environment;

export const EnvironmentColorMap: Record<Environment, TagProps['type']> = {
  [Environment.LOCAL]: 'lightblue',
  [Environment.DEV]: 'lightblue',
  [Environment.UAT]: 'warning',
  [Environment.PROD]: 'darkblue'
};

export const EnvironmentBackgroundImageMap: Record<Environment, StaticImageData> = {
  [Environment.LOCAL]: BG_LIGHTBLUE,
  [Environment.DEV]: BG_LIGHTBLUE,
  [Environment.UAT]: BG_YELLOW,
  [Environment.PROD]: BG_DARKBLUE
};
