import { z } from 'zod';

export const DeviceStatusTypeSchema = z.object({
  description: z.string(),
  name: z.string(),
  deviceStatusId: z.number()
});

export const GenderSchema = z.object({
  description: z.string(),
  name: z.string(),
  genderId: z.number()
});

export const InactiveReasonTypeSchema = z.object({
  inactiveReasonId: z.number(),
  name: z.string().optional(),
  description: z.string().optional()
});

export const ModeOfContactSchema = z.object({
  description: z.string(),
  name: z.string(),
  preferredModeOfContactId: z.number()
});

export const ProviderTypeSchema = z.object({
  description: z.string(),
  name: z.string(),
  providerTypeId: z.number()
});

export const StateSchema = z.object({
  description: z.string(),
  name: z.string(),
  stateId: z.number()
});

export const TimeZoneSchema = z.object({
  canonicalName: z.string(),
  description: z.string(),
  name: z.string(),
  timeZoneId: z.number()
});

export const WorklistTypeSchema = z.object({
  description: z.string(),
  name: z.string(),
  worklistTypeId: z.number()
});

export const WorklistItemTypeSchema = z.object({
  description: z.string(),
  name: z.string(),
  worklistItemTypeId: z.number()
});

export const WorklistItemStatusTypeSchema = z.object({
  description: z.string(),
  name: z.string(),
  worklistItemStatusTypeId: z.number()
});

export type Gender = z.infer<typeof GenderSchema>;
export type DeviceStatusType = z.infer<typeof DeviceStatusTypeSchema>;
export type InactiveReasonType = z.infer<typeof InactiveReasonTypeSchema>;
export type ModeOfContact = z.infer<typeof ModeOfContactSchema>;
export type ProviderType = z.infer<typeof ProviderTypeSchema>;
export type State = z.infer<typeof StateSchema>;
export type TimeZone = z.infer<typeof TimeZoneSchema>;
export type WorklistItemStatusType = z.infer<typeof WorklistItemStatusTypeSchema>;
export type WorklistItemType = z.infer<typeof WorklistItemTypeSchema>;
export type WorklistType = z.infer<typeof WorklistTypeSchema>;
