import { z } from 'zod';
import { ProviderTypeSchema } from './referenceData';

export const ProviderSiteSchema = z.object({
  locationIEN: z.number().nullable().optional(),
  providerDUZ: z.string().nullable(),
  providerId: z.number().nullable(),
  providerSiteId: z.number().nullable().optional(),
  siteNumber: z.string().nullable()
});

export const ProviderSchema = z.object({
  created: z.string(),
  firstName: z.string().min(1, 'First Name is required'),
  isDeleted: z.boolean(),
  lastName: z.string().min(1, 'Last Name is required'),
  name: z.string(),
  note: z.string().nullable().optional(),
  npi: z.string().nullable(),
  officeInformationNote: z.string().nullable().optional(),
  pointOfContactName: z.string().nullable().optional(),
  pointOfContactRoleName: z.string().nullable().optional(),
  preferredModeOfContactId: z.number().nullable(),
  providerId: z.number(),
  providerSites: z.array(ProviderSiteSchema).nullable(),
  providerType: ProviderTypeSchema.nullable(),
  providerTypeId: z.number().min(1, 'Provider Type is required'),
  providerTypeName: z.string().nullable(),
  updatedByUserId: z.number().nullable()
});

export const ProviderUpdateSchema = z.object({
  created: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  name: z.string(),
  npi: z.string().optional(),
  providerId: z.number(),
  providerTypeId: z.number(),
  ProviderSites: z.array(ProviderSiteSchema).optional()
});

export const ProviderUpdateVASchema = ProviderUpdateSchema.extend({
  locationIEN: z.number().nullable().optional(),
  providerDUZ: z.string().nullable().optional(),
  siteNumber: z.string().nullable()
});

export const ProviderAddSchema = ProviderUpdateSchema.partial({
  providerId: true
});

export const ProviderAddVASchema = ProviderUpdateVASchema.partial({
  providerId: true
});

export const ProviderSearchItemAddressSchema = z.object({
  addressLine1: z.string(),
  addressLine2: z.string().nullable(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  phoneNumber: z.string(),
  addressType: z.string().nullable()
});

export const ProviderSearchItemSchema = z.object({
  addresses: z.array(ProviderSearchItemAddressSchema),
  credential: z.string(),
  created: z.string().nullable().optional(),
  firstName: z.string(),
  fromRegistry: z.boolean(),
  lastName: z.string(),
  name: z.string().nullable(),
  note: z.string(),
  number: z.string(),
  phoneNumbers: z.array(z.object({ phoneNumber: z.string() })),
  prefix: z.string(),
  providerId: z.number(),
  providerTypeId: z.number(),
  npi: z.string().nullable(),
  locationIEN: z.number().nullable(),
  siteNumber: z.string().nullable(),
  providerDUZ: z.string().nullable(),
  taxonomy: z.object({
    description: z.string(),
    code: z.string(),
    state: z.string()
  })
});

export const ProviderTableItemSchema = z.object({
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  locationIEN: z.number().nullable(),
  locationClinicName: z.string().nullable().optional(),
  name: z.string().nullable(),
  npi: z.string().nullable(),
  providerDUZ: z.string().nullable(),
  providerId: z.number(),
  siteNumber: z.string().nullable(),
  providerTypeId: z.number(),
  providerSiteId: z.number().nullable(),
  addresses: z.array(ProviderSearchItemAddressSchema),
  phoneNumbers: z.array(z.object({ phoneNumber: z.string() }))
});

export type Provider = z.infer<typeof ProviderSchema>;
export type ProviderAdd = z.infer<typeof ProviderAddSchema>;
export type ProviderAddVA = z.infer<typeof ProviderAddVASchema>;
export type ProviderUpdate = z.infer<typeof ProviderUpdateSchema>;
export type ProviderUpdateVA = z.infer<typeof ProviderUpdateVASchema>;
export type ProviderSearchItem = z.infer<typeof ProviderSearchItemSchema>;
export type ProviderSearchItemAddress = z.infer<typeof ProviderSearchItemAddressSchema>;
export type ProviderTableItem = z.infer<typeof ProviderTableItemSchema>;
export type ProviderSite = z.infer<typeof ProviderSiteSchema>;
