import { conditionalClasses } from '@/utils/tailwind';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { type FC } from 'react';
interface AlertProps extends React.ComponentPropsWithoutRef<'div'> {
  // TODO: add prop for passing icon?
  id: string;
  message: React.ReactNode;
  className?: string;
}
export const Alert: FC<AlertProps> = ({
  id,
  message,
  className,
  ...props
}) => {
  return <div id={id} className={conditionalClasses('flex animate-fade-down items-center rounded-lg border border-blue-300 bg-blue-50 p-4 text-sm text-podi-primary-hover animate-duration-[250ms] dark:border-blue-800 dark:bg-gray-800 dark:text-blue-400', className)} role="alert" {...props} data-sentry-component="Alert" data-sentry-source-file="Alert.tsx">
      <InformationCircleIcon fill="currentColor" aria-hidden="true" className="me-3 inline h-4 w-4 flex-shrink-0" data-sentry-element="InformationCircleIcon" data-sentry-source-file="Alert.tsx" />
      <span className="sr-only">Info Alert</span>
      <div>{message}</div>
    </div>;
};