import { conditionalClasses } from '@/utils/tailwind';
import { Menu as HeadlessMenu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
export interface MenuProps {
  id: string;
  button: string | JSX.Element;
  items: JSX.Element[];
  className?: string;
  buttonClassName?: string;
}
export function Menu({
  id,
  button,
  items,
  className,
  buttonClassName
}: MenuProps) {
  return <HeadlessMenu as="div" id={id} className={`relative `} data-sentry-element="HeadlessMenu" data-sentry-component="Menu" data-sentry-source-file="Menu.tsx">
      <HeadlessMenu.Button as="button" className={buttonClassName} data-sentry-element="unknown" data-sentry-source-file="Menu.tsx">
        {button}
      </HeadlessMenu.Button>
      <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95" data-sentry-element="Transition" data-sentry-source-file="Menu.tsx">
        <HeadlessMenu.Items as="div" className={conditionalClasses('absolute right-0 z-50 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none', className)} data-sentry-element="unknown" data-sentry-source-file="Menu.tsx">
          {items.map((item, index) => <HeadlessMenu.Item as="div" key={id + index + 'menu'}>
              {({
            active
          }) => <div className={conditionalClasses({
            'bg-podi-primary text-white': active
          }, 'block px-3 py-1 text-base leading-6 transition-all')}>{item}</div>}
            </HeadlessMenu.Item>)}
        </HeadlessMenu.Items>
      </Transition>
    </HeadlessMenu>;
}