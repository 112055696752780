import { conditionalClasses } from '@/utils/tailwind';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/navigation';
import type { FC, ReactNode } from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { ActionButton } from './ActionButton';
import { CustomLink } from './CustomLink';
type PageItem = {
  title: string;
  component: React.ReactNode;
  validator?: boolean;
  errorHandler?: () => boolean;
};
interface ContainerProps extends React.ComponentPropsWithoutRef<'div'> {
  pages: PageItem[];
  onCancel?: () => void;
  onFinish: () => void;
  isLoading?: boolean;
}
export const PagedWizard: FC<ContainerProps> = ({
  className,
  pages,
  onCancel,
  onFinish,
  isLoading,
  ...props
}) => {
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPage, setCurrentPage] = useState<ReactNode>();
  const [currentTitle, setCurrentTitle] = useState(pages?.[0]?.title || '');
  const [currentValidator, setCurrentValidator] = useState<boolean | undefined>(false);
  const currentErrorHandlerRef = useRef<(() => boolean) | null>(null); // TODO this is not best practice but a workaround until time to fix this wizard.
  const router = useRouter();
  const errorHandler = () => {
    return false;
  };

  // type PageItem = {
  //   title: string
  //   component: React.ReactNode
  //   validator?: boolean
  //   errorHandler?: () => boolean
  // }

  useEffect(() => {
    setCurrentPage(pages?.[currentPageNumber]?.component);
    setCurrentTitle(pages?.[currentPageNumber]?.title || '');
    setCurrentValidator(pages?.[currentPageNumber]?.validator);
    currentErrorHandlerRef.current = pages?.[currentPageNumber]?.errorHandler || null;
  }, [currentPage, currentTitle, pages, currentPageNumber, currentValidator]);
  const nextPage = () => {
    if (currentPageNumber < pages.length - 1) {
      setCurrentPage(pages?.[currentPageNumber + 1]?.component);
      setCurrentTitle(pages?.[currentPageNumber + 1]?.title || '');
      setCurrentPageNumber(currentPageNumber + 1);
      setCurrentValidator(pages?.[currentPageNumber + 1]?.validator);
    } else {
      onFinish();
    }
  };
  const previousPage = () => {
    if (currentPageNumber > 0) {
      setCurrentPage(pages?.[currentPageNumber - 1]?.component);
      setCurrentTitle(pages?.[currentPageNumber - 1]?.title || '');
      setCurrentPageNumber(currentPageNumber - 1);
      setCurrentValidator(pages?.[currentPageNumber - 1]?.validator);
    }
  };
  const hasErrors = () => {
    if (!currentErrorHandlerRef.current) return false;
    errorHandler();
    return currentErrorHandlerRef.current();
  };
  const showPager = () => {
    return <div className="mt-4 flex flex-row items-center justify-center pl-10" data-sentry-component="showPager" data-sentry-source-file="PagedWizard.tsx">
        {pages.map((item, index) => {
        return <Fragment key={index}>
              <div className="z-10">
                <div className="mt-4 w-8">
                  <div key={index} className={'rounded-full px-3 py-1 text-white ' + (currentPageNumber <= index - 1 ? '  bg-podi-primary-disabled' : ' bg-podi-primary font-bold')}>
                    {index + 1}
                  </div>
                </div>
                <div className={'-ml-4 mt-2 h-[32px] w-[100px]'}></div>
                <div className="relative">
                  <div className={'absolute -ml-[34px] -mt-7 w-[100px] items-center justify-center overflow-x-clip text-center text-xs/[1rem]' + (currentPageNumber <= index - 1 ? '' : ' font-bold')}>
                    {item.title}
                  </div>
                </div>
              </div>
              {index < pages.length - 1 ? <div className={'-ml-[55px] -mt-6 h-2 w-14 bg-podi-primary ' + (currentPageNumber <= index ? 'bg-podi-primary-disabled' : 'bg-podi-primary')}></div> : null}
              {index < pages.length - 1 ? <div className={'-ml-12 -mt-6 h-2 w-14 bg-podi-primary ' + (currentPageNumber <= index ? 'bg-podi-primary-disabled' : 'bg-podi-primary')}></div> : null}
              {index < pages.length - 1 ? <div className={'-mr-0.5 -mt-6 h-2 w-14 bg-podi-primary ' + (currentPageNumber <= index ? 'bg-podi-primary-disabled' : 'bg-podi-primary')}></div> : null}
            </Fragment>;
      })}
      </div>;
  };
  const showButtons = () => {
    return <div className="flex flex-col items-end justify-end gap-5 pb-2 pt-4 sm:flex-row sm:items-center " data-sentry-component="showButtons" data-sentry-source-file="PagedWizard.tsx">
        <CustomLink href="#" id="close-modal" text="Cancel" onClick={onCancel ? onCancel : () => router.back()} data-sentry-element="CustomLink" data-sentry-source-file="PagedWizard.tsx" />
        <div className="flex items-center justify-end gap-5">
          <div className="relative">
            <ChevronLeftIcon className={conditionalClasses('pointer-events-none absolute left-6 -mt-[9px] h-5 w-5 text-white')} aria-hidden="true" data-sentry-element="ChevronLeftIcon" data-sentry-source-file="PagedWizard.tsx" />
          </div>
          <ActionButton id="show-popup" text="  Previous" className="mx-0 w-auto p-2 pl-7 pr-3" type="submit" onClick={() => previousPage()} isDisabled={currentPageNumber == 0} data-sentry-element="ActionButton" data-sentry-source-file="PagedWizard.tsx" />
          <ActionButton isLoading={isLoading} id="show-popup" text={currentPageNumber == pages.length - 1 ? 'Save' : 'Next'} onMouseEnter={() => {
          hasErrors();
        }} className={conditionalClasses('mx-0 w-auto p-2 pl-3', currentPageNumber == pages.length - 1 ? 'pr-3' : 'pr-7')} type="submit" onClick={() => {
          if (!hasErrors()) nextPage();
        }} data-sentry-element="ActionButton" data-sentry-source-file="PagedWizard.tsx" />
        </div>
        <div className={'relative' + (currentPageNumber == pages.length - 1 ? 'hidden' : '')}>
          <ChevronRightIcon className={conditionalClasses('pointer-events-none absolute bottom-[30px] right-1 -mt-[9px] h-5 w-5 text-white sm:right-6 sm:top-0')} aria-hidden="true" data-sentry-element="ChevronRightIcon" data-sentry-source-file="PagedWizard.tsx" />
        </div>
      </div>;
  };
  return <div className={conditionalClasses('mx-auto max-w-7xl px-4 sm:px-6 lg:px-8', className)} {...props} data-sentry-component="PagedWizard" data-sentry-source-file="PagedWizard.tsx">
      {showPager()}
      <div className="divide-y pb-4 pt-10">
        <span className="border-separate text-lg font-bold">{currentTitle}</span>
      </div>
      {currentPage}
      {showButtons()}
    </div>;
};