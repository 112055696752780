import { conditionalClasses } from '@/utils/tailwind';
import { Switch } from '@headlessui/react';
interface SwitchToggleProps {
  enabled: boolean;
  setEnabled: (e: boolean) => void;
  label: string;
  disabled: boolean;
}
export const SwitchToggle: React.FC<SwitchToggleProps> = ({
  enabled,
  setEnabled,
  label,
  disabled
}) => {
  return <Switch.Group as="div" className="flex items-center" data-sentry-element="unknown" data-sentry-component="SwitchToggle" data-sentry-source-file="SwitchToggle.tsx">
      <Switch as="div" checked={enabled} onChange={setEnabled} className={conditionalClasses(enabled && !disabled ? 'bg-podi-primary' : 'bg-gray-200', 'relative inline-flex h-[17px] w-8 flex-shrink-0 cursor-pointer rounded-full  outline-none transition-colors duration-200 ease-in-out  focus:ring-podi-primary focus:ring-offset-2')} disabled={disabled} data-sentry-element="Switch" data-sentry-source-file="SwitchToggle.tsx">
        <span aria-hidden="true" className={conditionalClasses(enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow outline-0 ring-0 transition duration-200 ease-in-out')} />
      </Switch>
      <Switch.Label as="label" className="ml-3 text-sm" data-sentry-element="unknown" data-sentry-source-file="SwitchToggle.tsx">
        <span className="text-base font-medium">{label}</span>
      </Switch.Label>
    </Switch.Group>;
};