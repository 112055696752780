'use client';

import { type AchillesUser } from '@/schemas/users';
import { type Pod } from '@/services/pods';
import { getUsers } from '@/services/users';
import { ACCESS_NAME, ROLE_NAME } from '@/utils/enums';
import { queryConfig } from '@/utils/react-query';
import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { type Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import { type Dispatch, type ReactNode, type SetStateAction, createContext, useContext, useEffect, useState } from 'react';
export const AuthContext = createContext<AuthContextProps>(null as unknown as AuthContextProps);
interface AuthContextProps {
  activePodiUsers: AchillesUser[];
  activeUsers: AchillesUser[];
  findUser: (user: Partial<AchillesUser>) => AchillesUser | undefined;
  getUsersWithAccess: (accessName: string) => AchillesUser[];
  getUsersWithRole: (roleName: ROLE_NAME) => AchillesUser[];
  hasAccessAboutMenu: () => boolean;
  hasAccessAdminMenu: () => boolean;
  hasAccessCustomersMenu: () => boolean;
  hasAccessDashboardsMenu: () => boolean;
  hasAccessEditCustomerWorklistItemCadence: () => boolean;
  hasAccessEditPatientWorklistItemCadence: () => boolean;
  hasAccessEscalationsWorklistAccess: () => boolean;
  hasAccessFlexWorklistAccess: () => boolean;
  hasAccessHomeMenu: () => boolean;
  hasAccessLogoutMenu: () => boolean;
  hasAccessPatientDeviceMenu: () => boolean;
  hasAccessPatientsMenu: () => boolean;
  hasAccessPodMenu: () => boolean;
  hasAccessPrescriptionsMenu: () => boolean;
  hasAccessPurchaseOrdersMenu: () => boolean;
  hasAccessStartCohort: () => boolean;
  hasMultipleRoles: () => boolean;
  isAdmin: () => boolean;
  isCareManager: () => boolean;
  isCareManagerSupervisor: () => boolean;
  isClinicalOperationsUser: () => boolean;
  isCohortAdministrator: () => boolean;
  isCommercialSalesSupervisor: () => boolean;
  isCommercialSalesUser: () => boolean;
  isDeviceOperationsUser: () => boolean;
  isSoftwareDeveloper: () => boolean;
  isVASalesSupervisor: () => boolean;
  isVASalesUser: () => boolean;
  isOnlyCareManager: () => boolean;
  selectedPodId: Pod['podId'];
  session: Session | null;
  setSelectedPodId: Dispatch<SetStateAction<Pod['podId']>>;
  setUser: Dispatch<SetStateAction<AchillesUser | undefined>>;
  user: AchillesUser | undefined;
  users: AchillesUser[] | undefined;
}
const achillesUserToSentryUser = ({
  email,
  userId,
  firstName,
  lastName
}: AchillesUser): Sentry.User => {
  return {
    id: userId,
    username: [firstName, lastName].join(' '),
    email,
    ip_address: '{{auto}}'
  };
};
export const AuthProvider = (props: {
  children: ReactNode;
}): JSX.Element => {
  const session = useSession()?.data;
  const {
    data: users
  } = useQuery({
    ...queryConfig('getUsers', getUsers),
    enabled: !!session?.user?.accessToken,
    retry: false
  });
  // TODO: Evaluate setting Sentry release via this network call vs. env variable approach.
  // const { data: releases } = useQuery({ ...queryConfig('getReleases', getReleases), enabled: !!session?.user?.accessToken, retry: false });
  const [user, setUser] = useState<AchillesUser>();
  const [selectedPodId, setSelectedPodId] = useState<Pod['podId']>(0);
  useEffect(() => {
    if (!session || !users?.length || !!user) return;
    const _user = users.find(u => u.email.toLowerCase().trim() === (session.user.email || '').toLowerCase().trim());
    if (_user) {
      setUser(_user);
      Sentry.setUser(achillesUserToSentryUser(_user));
    } else {
      Sentry.setUser({
        email: session.user.email || ''
      });
      Sentry.captureException(new Error('User not found in Achilles.'));
    }
  }, [session, users]);
  const findUser = ({
    userId,
    email
  }: Partial<AchillesUser>): AchillesUser | undefined => {
    return users?.find(user => user.userId === userId || user.email === email);
  };

  // Role checks
  const hasRole = (roleName: ROLE_NAME) => {
    return !!user?.userRoles.some(userRole => userRole?.role?.name === roleName);
  };
  const hasMultipleRoles = (): boolean => {
    return (user?.userRoles?.length || 0) > 1;
  };
  const getUsersWithRole = (roleName: ROLE_NAME): AchillesUser[] => {
    return (users || []).filter(user => user.userRoles.some(role => role.role?.name === roleName));
  };
  const isAdmin = () => hasRole(ROLE_NAME.Administrator);
  const isCareManager = () => hasRole(ROLE_NAME.CareManager);
  const isDeviceOperationsUser = () => hasRole(ROLE_NAME.DeviceOperations);
  const isClinicalOperationsUser = () => hasRole(ROLE_NAME.ClinicalOperations);
  const isVASalesUser = () => hasRole(ROLE_NAME.VASales);
  const isCommercialSalesUser = () => hasRole(ROLE_NAME.CommercialSales);
  const isSoftwareDeveloper = () => hasRole(ROLE_NAME.SoftwareDeveloper);
  const isCareManagerSupervisor = () => hasRole(ROLE_NAME.CareManagerSupervisor);
  const isVASalesSupervisor = () => hasRole(ROLE_NAME.VASalesSupervisor);
  const isCommercialSalesSupervisor = () => hasRole(ROLE_NAME.CommercialSalesSupervisor);
  const isCohortAdministrator = () => hasRole(ROLE_NAME.CohortAdministrator);

  // User has specified role, and no others
  const isOnlyCareManager = () => isCareManager() && !hasMultipleRoles();

  // Access checks
  const hasAccess = (accessName: ACCESS_NAME) => {
    return !!user?.userRoles.some(userRole => userRole?.role?.roleAccesses?.some(accesses => accesses?.access?.name === accessName));
  };
  const getUsersWithAccess = (accessName: string): AchillesUser[] => {
    return (users || []).filter(user => user.userRoles.some(role => role.role.roleAccesses.some(access => access.access?.name === accessName)));
  };
  const hasAccessAdminMenu = () => hasAccess(ACCESS_NAME.AdminMenu);
  const hasAccessHomeMenu = () => hasAccess(ACCESS_NAME.HomeMenu);
  const hasAccessDashboardsMenu = () => hasAccess(ACCESS_NAME.DashboardsMenu);
  const hasAccessCustomersMenu = () => hasAccess(ACCESS_NAME.CustomersMenu);
  const hasAccessPatientsMenu = () => hasAccess(ACCESS_NAME.PatientsMenu);
  const hasAccessPurchaseOrdersMenu = () => hasAccess(ACCESS_NAME.PurchaseOrdersMenu);
  const hasAccessPrescriptionsMenu = () => hasAccess(ACCESS_NAME.PrescriptionsMenu);
  const hasAccessPatientDeviceMenu = () => hasAccess(ACCESS_NAME.PatientDeviceMenu);
  const hasAccessAboutMenu = () => hasAccess(ACCESS_NAME.AboutMenu);
  const hasAccessLogoutMenu = () => hasAccess(ACCESS_NAME.LogoutMenu);
  const hasAccessFlexWorklistAccess = () => hasAccess(ACCESS_NAME.FlexWorklistAccess);
  const hasAccessEscalationsWorklistAccess = () => hasAccess(ACCESS_NAME.EscalationsWorklistAccess);
  const hasAccessEditPatientWorklistItemCadence = () => hasAccess(ACCESS_NAME.EditPatientWorklistItemCadence);
  const hasAccessEditCustomerWorklistItemCadence = () => hasAccess(ACCESS_NAME.EditCustomerWorklistItemCadence);
  const hasAccessPodMenu = () => hasAccess(ACCESS_NAME.PodMenu);
  const hasAccessStartCohort = () => hasAccess(ACCESS_NAME.StartCohort);
  const value: AuthContextProps = {
    activePodiUsers: (users || []).filter(user => user.email.includes('@podimetrics.com') && !user.isServiceAccount && user.isActive),
    activeUsers: (users || []).filter(user => user.isActive),
    findUser,
    getUsersWithAccess,
    getUsersWithRole,
    hasAccessAboutMenu,
    hasAccessAdminMenu,
    hasAccessCustomersMenu,
    hasAccessDashboardsMenu,
    hasAccessEditCustomerWorklistItemCadence,
    hasAccessEditPatientWorklistItemCadence,
    hasAccessEscalationsWorklistAccess,
    hasAccessFlexWorklistAccess,
    hasAccessHomeMenu,
    hasAccessLogoutMenu,
    hasAccessPatientDeviceMenu,
    hasAccessPatientsMenu,
    hasAccessPodMenu,
    hasAccessPrescriptionsMenu,
    hasAccessPurchaseOrdersMenu,
    hasAccessStartCohort,
    hasMultipleRoles,
    isAdmin,
    isCareManager,
    isCareManagerSupervisor,
    isClinicalOperationsUser,
    isCohortAdministrator,
    isCommercialSalesSupervisor,
    isCommercialSalesUser,
    isDeviceOperationsUser,
    isSoftwareDeveloper,
    isVASalesSupervisor,
    isVASalesUser,
    isOnlyCareManager,
    selectedPodId,
    session,
    setSelectedPodId,
    setUser,
    user,
    users
  };
  return <AuthContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="useAuth.tsx">{props.children}</AuthContext.Provider>;
};
export const useAuth = (): AuthContextProps => useContext(AuthContext);