import { type Dispatch, type SetStateAction } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './scn/ui/select';
import { Skeleton } from './scn/ui/skeleton';
export const InputSkeleton = (name: string) => {
  return <div className="flex min-h-[72px] w-full flex-col" data-sentry-component="InputSkeleton" data-sentry-source-file="SelectBool.tsx">
      <div className="space-y-2">
        <p className="mb-2 block text-sm font-medium leading-3 text-gray-900">{name}</p>
        <Skeleton className="h-10 w-full" data-sentry-element="Skeleton" data-sentry-source-file="SelectBool.tsx" />
      </div>
    </div>;
};
const handleBoolSelectInput = (value: boolean | undefined) => {
  if (typeof value === 'undefined') return '';
  if (typeof value === 'boolean') {
    if (value) return 'True';
    return 'False';
  }
};
type SelectBoolType = {
  setState: Dispatch<SetStateAction<boolean | undefined>>;
  state: boolean | undefined;
  name: string;
  required: boolean;
};
export function SelectBool({
  setState,
  state,
  name,
  required = false
}: SelectBoolType) {
  return <Select onValueChange={value => {
    setState(value.toLowerCase() === 'true');
  }} value={handleBoolSelectInput(state)} required={required} data-sentry-element="Select" data-sentry-component="SelectBool" data-sentry-source-file="SelectBool.tsx">
      <p className="mb-2 block text-sm font-medium leading-3 text-gray-900">{`${name} ${required ? '*' : ''}`}</p>
      <SelectTrigger className="min-h-6 w-full" data-sentry-element="SelectTrigger" data-sentry-source-file="SelectBool.tsx">
        <SelectValue placeholder={name} className="block min-h-6" data-sentry-element="SelectValue" data-sentry-source-file="SelectBool.tsx" />
      </SelectTrigger>
      <SelectContent data-sentry-element="SelectContent" data-sentry-source-file="SelectBool.tsx">
        <SelectItem value="True" data-sentry-element="SelectItem" data-sentry-source-file="SelectBool.tsx">True</SelectItem>
        <SelectItem value="False" data-sentry-element="SelectItem" data-sentry-source-file="SelectBool.tsx">False</SelectItem>
      </SelectContent>
    </Select>;
}